















































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import Badge from '@/app/ui/components/Badge/index.vue';
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue';
import Button from '@/app/ui/components/Button/index.vue'
import CheckBoxUncheckedIcon from '@/app/ui/assets/check_box_unchecked.vue';
import CheckBoxCheckedIcon from '@/app/ui/assets/check_box_checked.vue'
import IconParcelPoint from '@/app/ui/assets/ics_f_parcel_poin.vue';
import { DataObject, HeaderObject } from '@/app/ui/components/DataTableV2/type';
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue';
import ModalAction from '@/app/ui/components/Modal/ModalAction.vue';
import controller from '@/app/ui/controllers/ProgramaticPointController';
import {
  ProgrammaticParcelPoinAndSaldo, ProgrammaticParcelPoinAndSaldoInviteeInviter,
  ProgrammaticParcelPoinAndSaldoInviteeInviterPagination
} from '@/domain/entities/Program';
import { Utils } from '@/app/infrastructures/misc';
import TextInput from "@/app/ui/components/TextInput/index.vue";
import DownloadIcon from "@/app/ui/assets/download_icon.vue";


@Component({
  components: {
    DownloadIcon,
    TextInput,
    ModalAction,
    PaginationNav,
    Button,
    DataTableV2,
    IconParcelPoint,
    CheckBoxUncheckedIcon,
    CheckBoxCheckedIcon,
    Badge
  },
})
export default class AllDataProgrammaticNeedApprovalListPage extends Vue {
  controller = controller
  parameters = {
    page: 1,
    perPage: 20,
    isApproved: false,
    keyword: '',
  }
  hasData = false
  headers: HeaderObject[] = [
    {
      title: 'checkbox',
      customStyle: {minWidth: '48px', maxWidth: '48px'},
    },
    {
      title: 'Program Name',
      customStyle: {minWidth: '220px', maxWidth: '220px'},
    },
    {
      title: 'Period',
      customStyle: {minWidth: '125px', maxWidth: '125px'},
    },
    {
      title: 'Customer ID',
      customStyle: {minWidth: '140px', maxWidth: '140px'},
    },
    {
      title: 'Account Name',
      customStyle: {minWidth: '155px', maxWidth: '155px'},
    },
    {
      title: 'Invitee/Inviter',
      customStyle: {minWidth: '110px', maxWidth: '110px'},
    },
    {
      title: 'Total Earn',
      customStyle: {minWidth: '155px', maxWidth: '155px'},
    },
    {
      title: 'Status',
      customStyle: {minWidth: '155px', maxWidth: '155px'},
    },
    {
      title: 'Action',
      customStyle: {minWidth: '280px', maxWidth: '280px'},
    },
  ]

  headersInner: HeaderObject[] = [
    {
      title: '',
      customStyle: {minWidth: '40px', maxWidth: '40px'},
    },
    {
      title: 'Customer ID',
      customStyle: {minWidth: '125px', maxWidth: '125px'},
    },
    {
      title: 'Customer ID invitee',
      customStyle: {minWidth: '125px', maxWidth: '125px'},
    },
    {
      title: 'STT',
      customStyle: {minWidth: '160px', maxWidth: '160px'},
    },
    {
      title: 'BKD Date',
      customStyle: {minWidth: '155px', maxWidth: '155px'},
    },
    {
      title: 'POD Date',
      customStyle: {minWidth: '155px', maxWidth: '155px'},
    },
    {
      title: 'Amount Paid',
      customStyle: {minWidth: '110px', maxWidth: '110px'},
    },
    {
      title: 'Percentage',
      customStyle: {minWidth: '155px', maxWidth: '155px'},
    },
    {
      title: 'Total Earn',
      customStyle: {minWidth: '155px', maxWidth: '155px'},
    },
    {
      title: ' ',
      customStyle: {minWidth: '265px', maxWidth: '265px'},
    },
  ]
  captureFetchChildIndex = -1
  dataInnerAdditionalData: {
    index: number,
    cursor: string,
    isOpen: boolean,
    totalItem: number,
    isLoading: boolean
  }[] = []

  dataItems: DataObject[][] = []
  dataItemsInner: DataObject[][][] = []
  listHasCollapse: boolean[] = []
  modalInfo = {
    action: '',
    parentIndex: -1,
    childIndex: -1,
    visible: false,
    title: '-',
    textButton: '-',
    textSecondary: 'Check Again',
  }

  created(): void {
    const query = this.$route.query
    if (query) {
      this.parameters.keyword = query.customerId ? query.customerId.toString() : ''
    }

    controller.getAllProgrammaticPointAndSaldo(this.parameters)
  }

  private searchData(): void {
    this.parameters.page = 1
    Utils.onParamsChanged('customerId', this.parameters.keyword)
    window.location.reload()
  }

  @Watch('controller.programmaticParcelPoinAndSaldoData', {deep: true})
  private onProgrammaticParcelPoinAndSaldoData(val: ProgrammaticParcelPoinAndSaldo[]): void {
    this.hasData = val.length > 0

    this.dataItems = []
    this.listHasCollapse = []
    val.forEach((data, index) => {
      const point = (data.disburseTo.startsWith('PARCEL') ? '' : 'Rp') + Utils.currencyDigit(data.point)
      const dataItemTable: DataObject[] = [
        {
          title: '',
          value: 'false',
          customStyle: {minWidth: '48px', maxWidth: '48px'},
        },
        {
          value: data.programmaticPointName,
          customStyle: {minWidth: '220px', maxWidth: '220px'},
        },
        {
          value: data.period,
          customStyle: {minWidth: '125px', maxWidth: '125px'},
        },
        {
          value: data.customerId,
          customStyle: {minWidth: '140px', maxWidth: '140px'},
        },
        {
          value: Utils.toCapitalize(data.customerName),
          customStyle: {minWidth: '155px', maxWidth: '155px'},
        },
        {
          value: Utils.toCapitalize(data.inviteeInviter),
          customStyle: {minWidth: '110px', maxWidth: '110px'},
        },
        {
          value: point,
          customStyle: {minWidth: '155px', maxWidth: '155px'},
        },
        {
          value: Utils.toCapitalize(data.status),
          customStyle: {minWidth: '155px', maxWidth: '155px'},
        },
        {
          value: index,
          customStyle: {minWidth: '280px', maxWidth: '280px'},
        },
      ]
      this.dataItemsInner.push([])
      this.dataInnerAdditionalData.push({index: index, cursor: '', isOpen: false, totalItem: 0, isLoading: false})
      this.listHasCollapse.push(data.hasChild)
      this.dataItems.push(dataItemTable)
    })
  }

  private isCheckboxSelectedOnExpandChild(parentIndex: number): boolean {
    if (this.dataItemsInner[parentIndex] && this.dataItemsInner[parentIndex].length > 0) {
      return this.dataItemsInner[parentIndex].every(val => val[0].title === 'true')
    }
    return false
  }

  private fetchChildData(index: number): void {
    const parentData = controller.programmaticParcelPoinAndSaldoData[index]
    const params: {
      programmaticPointId: number,
      periodDate: string,
      customerId: number,
      inviteeInviter: string,
      cursor: string,
      isNeedApproval: boolean,
    } = {
      programmaticPointId: parentData.programmaticPointId,
      periodDate: parentData.periodDate,
      customerId: parentData.customerId,
      inviteeInviter: parentData.inviteeInviter,
      cursor: '',
      isNeedApproval: true,
    }
    if (this.dataInnerAdditionalData[index].cursor !== '') {
      params.cursor = this.dataInnerAdditionalData[index].cursor
    }
    controller.getAllProgrammaticPointAndSaldoInviteInviter(params)
    this.captureFetchChildIndex = index
    this.dataInnerAdditionalData[index].isLoading = true
  }

  private onExpandTable(props: Record<string, unknown>, index: number, action: 'close' | 'open'): void {
    if (this.dataInnerAdditionalData[index].isOpen && this.dataItemsInner[index].length > 0) {
      return
    }
    this.fetchChildData(index)
  }

  // fetch child data
  @Watch('controller.programmaticPointInviteeInviterData', {deep: true})
  private onPaginationProgrammaticPointInviteeInviter(val: ProgrammaticParcelPoinAndSaldoInviteeInviter[]): void {
    if (this.captureFetchChildIndex === -1) {
      return
    }

    const parentData = controller.programmaticParcelPoinAndSaldoData[this.captureFetchChildIndex]
    const isInviter = parentData.inviteeInviter.toLowerCase() === 'inviter'

    val.forEach((childData, index) => {
      const pointEarn = (parentData.disburseTo.startsWith('PARCEL') ? '' : 'Rp') + Utils.currencyDigit(childData.totalEarning)
      const formatDate = Utils.formatDate(childData.podDate, 'DD MMMM YYYY')
      const formatDateBkd = Utils.formatDate(childData.bkdDate, 'DD MMMM YYYY')
      const dataInner = [
        {
          title: '',
          value: 'false',
          customStyle: {minWidth: '40px', maxWidth: '40px'},
        },
        {
          value: childData.customerId,
          customStyle: {minWidth: '125px', maxWidth: '125px'},
        },
        {
          value: isInviter ? childData.inviteeCustomerId : '-',
          customStyle: {minWidth: '125px', maxWidth: '125px'},
        },
        {
          value: childData.packageId,
          customStyle: {minWidth: '160px', maxWidth: '160px'},
        },
        {
          value: formatDateBkd,
          customStyle: {minWidth: '155px', maxWidth: '155px'},
        },
        {
          value: formatDate,
          customStyle: {minWidth: '155px', maxWidth: '155px'},
        },
        {
          value: 'Rp' + Utils.currencyDigit(childData.amountPaid),
          customStyle: {minWidth: '110px', maxWidth: '110px'},
        },
        {
          value: childData.percentage + '%',
          customStyle: {minWidth: '155px', maxWidth: '155px'},
        },
        {
          value: pointEarn,
          customStyle: {minWidth: '155px', maxWidth: '155px'},
        },
        {
          title: String(childData.programmaticPointHistoryId),
          value: index,
          customStyle: {minWidth: '265px', maxWidth: '265px'},
        },
      ]

      this.dataItemsInner[this.captureFetchChildIndex].push(dataInner)
      this.dataInnerAdditionalData[this.captureFetchChildIndex].isOpen = true
    })
  }

  // fetch pagination child data
  @Watch('controller.paginationProgrammaticPointInviteeInviter', {deep: true})
  private onPaginationProgrammaticPointInviteeInviterChange(val: ProgrammaticParcelPoinAndSaldoInviteeInviterPagination): void {
    if (this.captureFetchChildIndex === -1) {
      return
    }

    this.dataInnerAdditionalData[this.captureFetchChildIndex].cursor = val.nextCursor || ''

    this.dataInnerAdditionalData[this.captureFetchChildIndex].totalItem = val.totalItem || 0
    this.dataInnerAdditionalData[this.captureFetchChildIndex].isLoading = false
  }

  @Watch('controller.isLoadingInviteeInviter', {deep: true})
  private onLoadingInviteeInviter(val: boolean): void {
    if (this.captureFetchChildIndex === -1) {
      return
    }

    this.dataInnerAdditionalData[this.captureFetchChildIndex].isLoading = val
  }

  private onClickCheckboxParentRow(props: { index: number }): void {
    if (!this.listHasCollapse[props.index]) {
      return
    }

    // open expand collapse if child of parent not fetch yet
    const getBtn = document.querySelector(`#btn-expand-collapse-${props.index}`)
    if (getBtn && !getBtn.classList.contains('isExpand')) {
      getBtn.dispatchEvent(new Event('click'))
    }

    if (this.dataItems[props.index][0].title) {
      this.dataItems[props.index][0].title = ''
      this.dataItems[props.index][0].value = 'false'
    } else {
      this.dataItems[props.index][0].title = 'true'
      this.dataItems[props.index][0].value = 'true'
    }

    if (this.dataItemsInner.length < props.index + 1) {
      return
    }

    const isAllSelected = this.dataItemsInner[props.index].every(val => val[0].title === 'true')
    const isSomeSelected = this.dataItemsInner[props.index].some(val => val[0].title === 'true')

    for (let dataItemInner of this.dataItemsInner[props.index]) {
      if (dataItemInner[0].title && isAllSelected) {
        dataItemInner[0].title = ''
        dataItemInner[0].value = 'false'
      } else if (isSomeSelected && this.dataItems[props.index][0].title === '') {
        dataItemInner[0].title = ''
        dataItemInner[0].value = 'false'
        this.dataItems[props.index][0].title = ''
        this.dataItems[props.index][0].value = 'false'
      } else {
        dataItemInner[0].title = 'true'
        dataItemInner[0].value = 'true'
      }
    }

    this.setDataSelected(props.index)
  }

  private onClickCheckboxChildRow(props: { index: number, parent: { index: number } }): void {
    if (!this.dataItemsInner[props.parent.index]) {
      return
    }
    const currentSelected = this.dataItemsInner[props.parent.index].filter(val => val[0].title === 'true')

    for (const dataItemInner of this.dataItemsInner[props.parent.index]) {
      if (currentSelected.length !== this.dataItemsInner[props.parent.index].length) {
        dataItemInner[0].title = 'true'
      } else {
        dataItemInner[0].title = ''
      }
    }
    this.setDataSelected(props.parent.index)
  }

  private setDataSelected(parentIndex: number): void {
    if (!this.dataItemsInner[parentIndex]) {
      return
    }

    const currentSelected = this.dataItemsInner[parentIndex].filter(val => val[0].title === 'true')

    const btnReject = <HTMLElement>this.$refs[`buttonParentReject${parentIndex}`]
    if (currentSelected.length === 0) {
      btnReject.innerText = `Reject`
    } else {
      btnReject.innerText = `Reject (${currentSelected.length})`
    }
    const btnApprove = <HTMLElement>this.$refs[`buttonParentApprove${parentIndex}`]
    if (currentSelected.length === 0) {
      btnApprove.innerText = `Approve`
      this.dataItems[parentIndex][0].title = ''
      this.dataItems[parentIndex][0].value = 'false'
    } else {
      this.dataItems[parentIndex][0].title = 'true'
      this.dataItems[parentIndex][0].value = 'true'
      btnApprove.innerText = `Approve (${currentSelected.length})`
    }
  }

  private onClickCheckboxChildInnerRow(props: { index: number, parent: { index: number } }): void {
    if (this.dataItemsInner[props.parent.index][props.index][0].title) {
      this.dataItemsInner[props.parent.index][props.index][0].title = ''
    } else {
      this.dataItemsInner[props.parent.index][props.index][0].title = 'true'
    }

    this.setDataSelected(props.parent.index)
  }

  private onClickTriggerAction(parentIndex: number, childIndex: number, action: 'reject' | 'approve'): void {
    // when child not fetch yet and parent checkbox not checked
    if (this.dataItems[parentIndex][0].title != 'true' && childIndex === -1) {
      this.onClickCheckboxParentRow({index: parentIndex})
      if (!this.dataInnerAdditionalData[parentIndex].isOpen) {
        return
      }
    }

    // when child not fetch yet and parent checkbox checked
    if (this.dataItems[parentIndex][0].title != 'true' && childIndex > -1) {
      this.dataItems[parentIndex][0].title = 'true'
      this.dataItemsInner[parentIndex][childIndex][0].title = 'true'
      this.setDataSelected(parentIndex)
    }

    // when child fetch and parent checkbox checked on parent
    const isAllChildSelected = this.dataItemsInner[parentIndex].every(val => val[0].title === 'true')
    if (this.dataItems[parentIndex][0].title == 'true' && childIndex > -1 && !isAllChildSelected) {
      for (let dataItemInner of this.dataItemsInner[parentIndex]) {
        dataItemInner[0].title = ''
        dataItemInner[0].value = 'false'
      }

      this.dataItemsInner[parentIndex][childIndex][0].title = 'true'
      this.setDataSelected(parentIndex)
    }

    const parentData = controller.programmaticParcelPoinAndSaldoData[parentIndex]
    const title = `Are you sure ${action} ${parentData.programmaticPointName}?`
    this.modalInfo = {
      action: action === 'reject' ? 'REJECTED' : 'APPROVED',
      parentIndex: parentIndex,
      childIndex: childIndex,
      visible: true,
      title: title,
      textSecondary: action === 'reject' ? 'Cancel' : 'Check Again',
      textButton: action === 'reject' ? 'Reject' : 'Yes, Approve',
    }
  }

  private loadMoreInnerChild(props: { index: number }): void {
    this.fetchChildData(props.index)
  }

  handleActionModal(): void {
    const listSelected: number[] = []
    this.dataItemsInner.forEach((dataItemInner, index) => {
      if (this.modalInfo.parentIndex !== index) {
        return
      }
      dataItemInner.forEach((dataItem, indexInner) => {
        if (dataItem[0].title === 'true') {
          const getProgrammaticPointHistoryId = this.dataItemsInner[index][indexInner][dataItem.length - 1].title
          if (getProgrammaticPointHistoryId) {
            listSelected.push(+getProgrammaticPointHistoryId)
          }
        }
      })
    })
    const eligibleUserId = controller.programmaticParcelPoinAndSaldoData[this.modalInfo.parentIndex].programmaticEligibleUserId
    controller.allProgrammaticPointApproval({
      status: this.modalInfo.action,
      listIDs: listSelected,
      programmaticEligibleUserId: eligibleUserId,
    })
  }

  @Watch("controller.successSubmitApproval", {deep: true})
  private onSubmitApproval(isSuccess: boolean): void {
    if (isSuccess) {
      this.modalInfo = {
        action: '',
        parentIndex: -1,
        childIndex: -1,
        visible: false,
        title: '-',
        textButton: '-',
        textSecondary: 'Check Again',
      }
      window.location.reload();
    }
  }

  private resetData(): void {
    this.dataItems = []
    this.dataItemsInner = []
    this.listHasCollapse = []
    controller.resetAllProgrammaticData()
  }

  private getExportUrl(): void {
    if (this.hasData) {
      const params = {
        params: {
          ...this.parameters,
          isNeedApproval: true,
        },
      }
      controller.getExportedFileNeedApprovalAllProgrammatic(params)
    }
  }

  beforeDestroy(): void {
    this.resetData()
  }
}
