














import { Component, Vue } from 'vue-property-decorator'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import CheckBoxUncheckedIcon from '@/app/ui/assets/check_box_unchecked.vue'
import CheckBoxMinusIcon from '@/app/ui/assets/check_box_minus_2.vue'
import CheckBoxCheckedIcon from '@/app/ui/assets/check_box_checked.vue'
import Badge from '@/app/ui/components/Badge/index.vue';
import Tabs from '@/app/ui/components/Tabs/index.vue'
import AllDataProgrammaticNeedApprovalListPage
  from '@/app/ui/views/Saldo/AllDataProgrammatic/NeedApproval/List/NeedApprovalList.vue';
import ProgrammaticDataList
  from '@/app/ui/views/Saldo/AllDataProgrammatic/ProgrammaticData/List/ProgrammaticDataList.vue';
import { Utils } from '@/app/infrastructures/misc';

@Component({
  components: {
    AllDataProgrammaticNeedApprovalListPage,
    ProgrammaticDataList,
    Tabs,
    Badge,
    CheckBoxCheckedIcon,
    CheckBoxMinusIcon,
    CheckBoxUncheckedIcon,
    DataTableV2
  }
})
export default class AllDataProgrammaticPage extends Vue {
  currentTab = 0
  itemTabs = [
    {label: 'Need Approval', value: 0},
    {label: 'Programmatic Data', value: 1}
  ]
  parameters = {
    tab: 'need-approval',
  }

  private commonOnTab(tab: number, onCreated: boolean): void {
    this.currentTab = tab
    if (tab === 0) {
      this.parameters.tab = 'need-approval'
      if (!onCreated) {
        let url = window.location.href;
        let urlWithDefault = url.split('?')[0];
        history.pushState(null, '', urlWithDefault);
      }
    } else {
      this.parameters.tab = 'programmatic-data'
    }
    Utils.onParamsChanged('tab', this.parameters.tab);
  }

  private onTabsClick(tab: number): void {
    this.commonOnTab(tab, false)
  }

  created(): void {
    const query = this.$route.query
    if (query.tab) {
      this.currentTab = query.tab == 'need-approval' ? 0 : 1
      this.commonOnTab(this.currentTab, true)
    } else {
      Utils.onParamsChanged('tab', this.parameters.tab);
    }
  }
}
