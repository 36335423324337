function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('modal-action',{attrs:{"data-testid":"adjustment-parcel-poin-preview-upload-bulky__modal-action-retry","visible":_vm.modalInfo.visible,"title":_vm.modalInfo.title,"description":"Make sure the program name is correct","icon":'confirmation',"buttonFull":true,"center":true,"is-disabled":_vm.controller.isLoadingSubmitApproval,"text-primary":_vm.modalInfo.textButton,"text-secondary":_vm.modalInfo.textSecondary,"isLoading":false},on:{"action":function($event){return _vm.handleActionModal()},"cancel":function () {_vm.modalInfo.visible = false}}}),_c('div',{staticClass:"flex mb-6"},[_c('div',{staticClass:"flex flex-col w-36 hdpi-laptop:w-44"},[_c('span',{staticClass:"font-semibold text-bgMatterhorn text-sm mb-1"},[_vm._v("Search Customer ID")]),_c('TextInput',{attrs:{"id":"customer-id","placeholder":"Customer ID","type":"number","allowPasteNumber":"","allowNullNumber":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchData($event)}},model:{value:(_vm.parameters.keyword),callback:function ($$v) {_vm.$set(_vm.parameters, "keyword", $$v)},expression:"parameters.keyword"}})],1),_c('Button',{staticClass:"self-end ml-3",on:{"click":_vm.searchData}},[_vm._v("Search")]),(!_vm.controller.isDownloading)?_c('a',{staticClass:"ml-10 flex flex-row items-center text-bgPrimary mt-5",class:_vm.hasData ? 'cursor-pointer' : 'cursor-not-allowed',attrs:{"aria-disabled":!_vm.hasData},on:{"click":function () {
          _vm.getExportUrl()
        }}},[_c('DownloadIcon',{staticClass:"mr-2"}),_vm._v(" "),_c('span',{staticClass:"font-semibold"},[_vm._v("Export to Excel")])],1):_c('span',{staticClass:"ml-10 mt-5 flex flex-row items-center text-bgPrimary cursor-wait font-semibold"},[_c('DownloadIcon',{staticClass:"mr-2"}),_vm._v("Downloading File... ")],1)],1),_vm._m(0),_c('data-table-v-2',{ref:"tableData",attrs:{"idIndex":0,"headers":_vm.headers,"data-items":_vm.dataItems,"is-loading":_vm.controller.isLoading,"list-has-collapse":_vm.listHasCollapse,"title-collapse":"Transaction List","id-btn-expand":"btn-expand-collapse"},on:{"on-expand-v2":_vm.onExpandTable},scopedSlots:_vm._u([{key:"header-0",fn:function(){return [_c('div',{staticClass:"cursor-pointer",attrs:{"data-testid":"payroll-bonus-list__main-checkbox"}})]},proxy:true},{key:"0",fn:function(ref){
        var rest = objectWithoutProperties( ref, [] );
        var props = rest;
return [_c('div',{staticClass:"cursor-pointer",class:!_vm.listHasCollapse[props.index] ? 'cursor-not-allowed' : '',attrs:{"data-testid":("payroll-bonus-list__item-checkbox-" + (props.index))},on:{"click":function($event){return _vm.onClickCheckboxParentRow(props)}}},[(props.data.title && props.data.title !== '')?_c('CheckBoxCheckedIcon',{attrs:{"fill":"#B82025"}}):_c('CheckBoxUncheckedIcon',{attrs:{"fill":!_vm.listHasCollapse[props.index] ? '#D4D5D8' : '#FFFFFF'}})],1)]}},{key:"6",fn:function(ref){
        var rest = objectWithoutProperties( ref, [] );
        var props = rest;
return [_c('div',{staticClass:"flex flex-row text-sm text-bgMatterhorn items-center"},[(!props.data.value.startsWith('Rp'))?_c('icon-parcel-point',{staticClass:"mr-1"}):_vm._e(),_c('span',[_vm._v(_vm._s(props.data.value))])],1)]}},{key:"7",fn:function(ref){
        var rest = objectWithoutProperties( ref, [] );
        var props = rest;
return [_c('div',{staticClass:"flex flex-row"},[_c('Badge',{staticClass:"w-28 flex justify-center py-1",attrs:{"type":props.data.value.startsWith('Pending') ? 'warning' : 'success'}},[_vm._v(_vm._s(props.data.value)+" ")])],1)]}},{key:"8",fn:function(ref){
        var rest = objectWithoutProperties( ref, [] );
        var props = rest;
return [_c('div',{staticClass:"flex flex-row w-full"},[_c('Button',{staticClass:"mr-2 py-2 pr-3",attrs:{"buttonStyle":"transparent","custom-padding":true,"disabled":!_vm.listHasCollapse[props.index]},on:{"click":function($event){return _vm.onClickTriggerAction(props.index, -1, 'reject')}}},[_c('span',{ref:("buttonParentReject" + (props.index))},[_vm._v("Reject")])]),_c('Button',{staticClass:"mr-2 py-2 px-3",attrs:{"buttonStyle":"red","custom-padding":true,"disabled":!_vm.listHasCollapse[props.index]},on:{"click":function($event){return _vm.onClickTriggerAction(props.index, -1, 'approve')}}},[_c('span',{ref:("buttonParentApprove" + (props.index))},[_vm._v("Approve")])])],1)]}},{key:"expand-collapse",fn:function(ref){
        var rest = objectWithoutProperties( ref, [] );
        var parentsProps = rest;
return [_c('div',{staticClass:"block px-4"},[_c('data-table-v-2',{staticClass:"table-expand-pp",attrs:{"idIndex":0,"headers":_vm.headersInner,"dataItems":_vm.dataItemsInner[parentsProps.index],"isLoading":_vm.dataItemsInner[parentsProps.index].length === 0 && _vm.dataInnerAdditionalData[parentsProps.index].isLoading},scopedSlots:_vm._u([{key:"header-0",fn:function(ref){
        var rest = objectWithoutProperties( ref, [] );
        var props = rest;
return [_c('div',{staticClass:"cursor-pointer",attrs:{"data-testid":"payroll-bonus-list__main-checkbox"},on:{"click":function($event){return _vm.onClickCheckboxChildRow(Object.assign({}, props, {parent: parentsProps}))}}},[(_vm.isCheckboxSelectedOnExpandChild(parentsProps.index))?_c('CheckBoxCheckedIcon',{attrs:{"fill":"#B82025"}}):_c('CheckBoxUncheckedIcon')],1)]}},{key:"0",fn:function(ref){
        var rest = objectWithoutProperties( ref, [] );
        var props = rest;
return [_c('div',{staticClass:"cursor-pointer",attrs:{"data-testid":("payroll-bonus-list__item-checkbox-" + (props.index))},on:{"click":function($event){return _vm.onClickCheckboxChildInnerRow(Object.assign({}, props, {parent: parentsProps}))}}},[(props.data.title && props.data.title !== '')?_c('CheckBoxCheckedIcon',{attrs:{"fill":"#B82025"}}):_c('CheckBoxUncheckedIcon')],1)]}},{key:"8",fn:function(ref){
        var rest = objectWithoutProperties( ref, [] );
        var props = rest;
return [_c('div',{staticClass:"flex flex-row text-sm text-bgMatterhorn items-center"},[(!props.data.value.startsWith('Rp'))?_c('span',[_c('icon-parcel-point',{staticClass:"mr-1"})],1):_vm._e(),_c('span',[_vm._v(_vm._s(props.data.value))])])]}},{key:"9",fn:function(ref){
        var rest = objectWithoutProperties( ref, [] );
        var props = rest;
return [_c('div',{staticClass:"flex flex-row"},[_c('Button',{staticClass:"mr-2 py-2 pr-3",attrs:{"buttonStyle":"transparent","custom-padding":true},on:{"click":function($event){return _vm.onClickTriggerAction(parentsProps.index, props.index, 'reject')}}},[_vm._v("Reject ")]),_c('Button',{staticClass:"mr-2 py-2 px-3",attrs:{"buttonStyle":"red","custom-padding":true},on:{"click":function($event){return _vm.onClickTriggerAction(parentsProps.index, props.index, 'approve')}}},[_vm._v("Approve ")])],1)]}}],null,true)}),_c('nav',{staticClass:"w-full flex justify-center mt-6 mb-2"},[(_vm.dataInnerAdditionalData[parentsProps.index].totalItem > 10 && _vm.dataInnerAdditionalData[parentsProps.index].totalItem > _vm.dataItemsInner[parentsProps.index].length)?_c('Button',{staticClass:"mr-2 py-2 px-3",attrs:{"buttonStyle":"outline","custom-padding":true,"disabled":_vm.dataInnerAdditionalData[parentsProps.index].isLoading},on:{"click":function($event){return _vm.loadMoreInnerChild(parentsProps)}}},[_vm._v(_vm._s(_vm.dataInnerAdditionalData[parentsProps.index].isLoading ? 'Loading...' : 'Load More')+" ")]):_vm._e()],1)],1)]}}])}),_c('div',[_c('PaginationNav',{ref:"pagination",attrs:{"page":_vm.parameters.page,"per-page":_vm.parameters.perPage,"total-item":_vm.controller.paginationProgrammaticParcelPoinAndSaldo.totalItem},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return (function () {_vm.controller.getAllProgrammaticPointAndSaldo(_vm.parameters)})($event)},"input":function () {_vm.controller.getAllProgrammaticPointAndSaldo(_vm.parameters)}},model:{value:(_vm.parameters.page),callback:function ($$v) {_vm.$set(_vm.parameters, "page", $$v)},expression:"parameters.page"}})],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-row justify-between mb-7 items-center"},[_c('h1',{staticClass:"font-semibold text-xl text-bgMatterhorn"},[_vm._v("All List Approving Program")])])}]

export { render, staticRenderFns }