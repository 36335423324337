





































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import DateTimePickerV2 from '@/app/ui/components/DateTimePickerV2/index.vue'
import { Utils } from '@/app/infrastructures/misc'
import Button from '@/app/ui/components/Button/index.vue'
import DownloadIcon from '@/app/ui/assets/download_icon.vue'
import IconParcelPoint from '@/app/ui/assets/ics_f_parcel_poin.vue';
import controller from '@/app/ui/controllers/ProgramaticPointController'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import { DataObject, HeaderObject } from '@/app/ui/components/DataTableV2/type'
import Badge from '@/app/ui/components/Badge/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import { ProgrammaticParcelPoinAndSaldo, ProgrammaticParcelPoinAndSaldoPagination } from '@/domain/entities/Program';


@Component({
  components: {IconParcelPoint, Badge, DataTableV2, Button, DateTimePickerV2, DownloadIcon, TextInput, PaginationNav}
})
export default class ProgrammaticDataList extends Vue {
  controller = controller
  hasData = false
  parameters: {
    page: number
    perPage: number
    keyword: string
    startPeriod: string | undefined
    endPeriod: string | undefined
    isApproved: boolean
  } = {
    page: 1,
    perPage: 20,
    keyword: '',
    startPeriod: '',
    endPeriod: '',
    isApproved: true,
  }
  form: {
    startPeriod: Date | undefined
    endPeriod: Date | undefined
  } = {
    startPeriod: undefined,
    endPeriod: undefined,
  }
  headers: HeaderObject[] = [
    {
      title: 'Program Name',
      customStyle: {minWidth: '140px', maxWidth: '140px'},
    },
    {
      title: 'Period',
      customStyle: {minWidth: '125px', maxWidth: '125px'},
    },
    {
      title: 'Customer ID',
      customStyle: {minWidth: '140px', maxWidth: '140px'},
    },
    {
      title: 'Account Name',
      customStyle: {minWidth: '155px', maxWidth: '155px'},
    },
    {
      title: 'Invitee/Inviter',
      customStyle: {minWidth: '110px', maxWidth: '110px'},
    },
    {
      title: 'Amount',
      customStyle: {minWidth: '155px', maxWidth: '155px'},
    },
    {
      title: 'Status',
      customStyle: {minWidth: '155px', maxWidth: '155px'},
    },
    {
      title: 'Action',
      customStyle: {minWidth: '155px', maxWidth: '155px'},
    },
  ]
  dataItems: DataObject[][] = []

  created(): void {
    const query = this.$route.query
    if (query) {
      this.parameters.keyword = query.keyword ? query.keyword.toString() : ''
      this.parameters.startPeriod = query.startPeriod ? query.startPeriod.toString() : ''
      this.parameters.endPeriod = query.endPeriod ? query.endPeriod.toString() : ''
      this.parameters.page = query.page ? parseInt(query.page.toString()) : 1
      this.parameters.perPage = query.perPage ? parseInt(query.perPage.toString()) : 20
      this.parameters.isApproved = true
      this.form.startPeriod = query.startPeriod ? new Date(query.startPeriod.toString()) : undefined
      this.form.endPeriod = query.endPeriod ? new Date(query.endPeriod.toString()) : undefined
    }

    controller.getAllProgrammaticPointAndSaldo(this.parameters)
  }

  private fetchData(isReset = true): void {
    if (isReset) {
      this.parameters.page = 1
    }

    if (!this.form.startPeriod && this.form.endPeriod) {
      this.form.startPeriod = this.form.endPeriod
      this.parameters.startPeriod = Utils.formatDate(this.form.startPeriod.toLocaleDateString(), 'YYYY-MM-DD')
    }

    if (this.form.startPeriod && !this.form.endPeriod) {
      this.form.endPeriod = this.form.startPeriod
      this.parameters.endPeriod = Utils.formatDate(this.form.endPeriod.toLocaleDateString(), 'YYYY-MM-DD')
    }

    controller.getAllProgrammaticPointAndSaldo(this.parameters)
    const objectEntries = Object.entries(this.parameters)
    objectEntries.forEach(([key, value]) => {
      Utils.onParamsChanged(key, value)
    })
  }

  @Watch('controller.paginationProgrammaticParcelPoinAndSaldo', {deep: true})
  private onPaginationProgrammaticParcelPoinAndSaldo(val: ProgrammaticParcelPoinAndSaldoPagination): void {
    this.parameters.page = val.page || 1
    this.parameters.perPage = val.perPage || 20
  }

  @Watch('controller.programmaticParcelPoinAndSaldoData', {deep: true})
  private onProgrammaticParcelPoinAndSaldoData(val: ProgrammaticParcelPoinAndSaldo[]): void {
    this.dataItems = []
    this.hasData = val.length > 0
    val.forEach((data, index) => {
      const point = (data.disburseTo.startsWith('PARCEL') ? '' : 'Rp') + Utils.currencyDigit(data.point)

      const dataItemTable: DataObject[] = [
        {
          value: data.programmaticPointName,
          customStyle: {minWidth: '140px', maxWidth: '140px'},
        },
        {
          value: data.period,
          customStyle: {minWidth: '125px', maxWidth: '125px'},
        },
        {
          value: data.customerId,
          customStyle: {minWidth: '140px', maxWidth: '140px'},
        },
        {
          value: Utils.toCapitalize(data.customerName),
          customStyle: {minWidth: '155px', maxWidth: '155px'},
        },
        {
          value: Utils.toCapitalize(data.inviteeInviter),
          customStyle: {minWidth: '110px', maxWidth: '110px'},
        },
        {
          value: point,
          customStyle: {minWidth: '155px', maxWidth: '155px'},
        },
        {
          value: Utils.toCapitalize(data.status),
          customStyle: {minWidth: '155px', maxWidth: '155px'},
        },
        {
          value: index,
          customStyle: {minWidth: '155px', maxWidth: '155px'},
        },
      ]

      this.dataItems.push(dataItemTable)
    })
  }

  private onStartDateChange(date: Date): void {
    this.form.startPeriod = date
    if (this.form.startPeriod && date) {
      this.parameters.startPeriod = Utils.formatDate(date.toLocaleDateString(), 'YYYY-MM-DD')
    } else {
      this.parameters.startPeriod = undefined
    }

    if (this.form.endPeriod) {
      const startDate = Utils.formatDate(date.toLocaleDateString(), 'DD-MMMM-YYYY')
      const endDate = Utils.formatDate(this.form.endPeriod.toLocaleDateString(), 'DD-MMMM-YYYY')
      if (this.form.endPeriod < date && startDate !== endDate) {
        this.form.endPeriod = undefined
        this.parameters.endPeriod = undefined
      }
    }
  }

  private onEndDateChange(date: Date): void {
    this.form.endPeriod = date
    if (this.form.endPeriod && date) {
      this.parameters.endPeriod = Utils.formatDate(date.toLocaleDateString(), 'YYYY-MM-DD')
    } else {
      this.parameters.endPeriod = undefined
    }
  }

  private goToDetail(props: { index: number }): void {
    const data = this.controller.programmaticParcelPoinAndSaldoData[props.index]
    let url = window.location.href;
    let [urlWithDefault, ...params] = url.split('?');
    urlWithDefault = urlWithDefault + '?' + params.join('&');
    history.pushState(null, '', urlWithDefault);
    this.$router.push(`/saldo/all-data-programatic/detail?programName=${data.programmaticPointName}&programmaticPointId=${data.programmaticPointId}&customerId=${data.customerId}&inviteeInviter=${data.inviteeInviter.toLowerCase()}&periodDate=${data.periodDate}`)
  }

  private getExportUrl(): void {
    if (this.hasData) {
      const params = {
        params: this.parameters,
      }
      controller.getExportedProgrammaticData(params)
    }
  }

  beforeDestroy(): void {
    this.dataItems = []
    this.controller.resetAllProgrammaticData()
  }
}
